/*
  Main JS
*/

$(function () {

	// Key visual
	OLEDUI.KvSwiper = function (t) {
		const swipeCont = t;
		const $btnPause = $(swipeCont).find(".btn-pause");
		const $btnPlay = $(swipeCont).find(".btn-play");
		const $btnPrev = $(swipeCont).find(".swiper-button-prev");
		const $btnNext = $(swipeCont).find(".swiper-button-next");
		const KvSwiper = new Swiper(t, {
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			loop: true,
			speed: 500,
			autoplay: {
				delay: 6000,
				disableOnInteraction: false,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			noSwiping: false,
			// preloadImages: true,
			allowTouchMove: true,
			// lazy: true,
			lazy: {
				loadPrevNext: true,
			},
			breakpoints: {
				// min 750px
				751: {
					speed: 1000,
					allowTouchMove: false,
				},
			},
			on: {
				beforeSlideChangeStart: function () {},
			},
		});
		// page load
		const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
		setTimeout(function () {
			$activePaging.addClass("on");
		}, 10);
		// event method
		KvSwiper.on("slideChange", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			$activePaging.siblings().removeClass("on");
			$(swipeCont).find(".full").removeClass("full");
			$activePaging.addClass("on");
		});
		function kvPause() {
			$btnPause.removeClass("active");
			$btnPlay.addClass("active");
			KvSwiper.autoplay.stop();
		}
		function kvPlay() {
			$btnPause.addClass("active");
			$btnPlay.removeClass("active");
			KvSwiper.autoplay.start();
		}
		// event listening
		$btnPrev.on("click", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			$activePaging.removeClass("on");
			$(swipeCont).find(".full").removeClass("full");
            $activePaging.addClass('full');
			kvPause();
		});
		$btnNext.on("click", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			$activePaging.removeClass("on");
			$(swipeCont).find(".full").removeClass("full");
            $activePaging.addClass('full');
			kvPause();
		});
		$btnPause.on("click", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			$(swipeCont).find(".full").removeClass("full");
            $activePaging.addClass('full');
			kvPause();
			$btnPause.addClass("clicked");
			$btnPlay.removeClass("clicked");
		});
		$btnPlay.on("click", function () {
			const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
			$activePaging.removeClass("on");
			$(swipeCont).find(".full").removeClass("full");
			kvPlay();
			$btnPlay.addClass("clicked");
			$btnPause.removeClass("clicked");
			setTimeout(function () {
				$activePaging.addClass("on");
			}, 100);
		});
		$(".swiper-pagination-bullet").on("click", function(){
            setTimeout(function () {
                const $activePaging = $(swipeCont).find(".swiper-pagination-bullet-active");
                $activePaging.removeClass("on");
                $(swipeCont).find(".full").removeClass("full");
                $activePaging.addClass('full');
                kvPause();
            }, 100);
        });
	};	
	if ($(".main-wrap .key-visual").length > 0) {
		const mainKv = $(".key-visual");
		OLEDUI.KvSwiper(".key-visual");
		mainKv.addClass("swiper-load");

		const kvEl = document.querySelector('.main-kv');
		const kvHeight = kvEl.offsetHeight;
		const imgEl = document.querySelectorAll('.main-kv .image-area');
		const txtEl = document.querySelectorAll('.main-kv .floating-area .text-box');
		const dimEl = document.querySelectorAll('.main-kv .dimmed');
		const mainKvAni = function(){
			let sc = $(window).scrollTop();
			imgEl.forEach(function(item){
				if (window.matchMedia("(min-width: 751px)").matches) {
					// desktop
					// item.style.marginTop = sc/5 + 'px';
					item.style.transform = "translateY("+sc/5 + 'px'+")";
				} else {
					// mobile
					item.style.transform = "translateY("+sc/10 + 'px'+")";
					// item.style.marginTop = sc/10 + 'px';
				}
				
			})
			txtEl.forEach(function(item){
				if (window.matchMedia("(min-width: 751px)").matches) {
					item.style.transform = "translateY("+-sc/30 + 'px'+")";
				} else {
					item.style.transform = "translateY("+ -sc/20 + 'px'+")";
				}
			})
			let opq = (sc / $(window).height());
			if(opq > 1) opq = 1;
			dimEl.forEach(function(item){	
				item.style.opacity = opq;
			})
		}
		$(window).on("scroll", function () {			
			let sc = $(window).scrollTop();			
			if(sc < kvHeight) {
				mainKvAni();
			}			
		});
		$(window).on("resize", function () {
			mainKvAni();
		});

		$(txtEl).find('.cta-wrap a').on('click', function() {
			pushDataLayer({
				'event': 'click_button',
				'click_button' : {
					'buttonText' : convertTitle($(this).text()),
					'sectionName' : 'key-visual-area',
					'sectionContentTitle' : convertTitle($(this).closest('.text-box').find('.title').text()),
					'sectionContentSub' : convertTitle($(this).closest('.text-box').find('.desc').text()),
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});

		$(kvEl).find('.swiper-slide > a').on('click', function() {
			const $link = $(this).parent().find('.cta-wrap a');
			pushDataLayer({
				'event': 'click_button',
				'click_button' : {
					'buttonText' : convertTitle($link.text()),
					'sectionName' : 'key-visual-area',
					'sectionContentTitle' : convertTitle($link.closest('.text-box').find('.title').text()),
					'sectionContentSub' : convertTitle($link.closest('.text-box').find('.desc').text()),
					'clickURL' : $link.attr('href') == '#' ? '' : $link.attr('href')
				}
			});
		});
	}

	// About OLED
	if ($(".main-wrap .about-oled").length > 0) {
		const $aboutOled = $(".main-wrap .about-oled");
		$aboutOled.find('a').on('click', function() {
			pushDataLayer({
				'event': 'click_button',
				'click_button' : {
					'buttonText' : convertTitle($(this).find('.btn').text()),
					'sectionName' : convertTitle($(this).find('.title').text()),
					'sectionContentTitle' : convertTitle($(this).find('.desc').text()),
					'sectionContentSub' : '',
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});
	// 	let inScreen = true;
	// 	$(window).on("scroll", function () {
	// 		const $this= $aboutOled.find(".image-area");
	// 		let t = $this.offset().top;
    //         let b = $this.offset().top + $this.height();
	// 		const sTop = $(window).scrollTop();
	// 		// animation 
	// 		if (OLEDUI.isInScreen(t, b)) {
	// 			$aboutOled.addClass("on");
	// 		}
	// 	});
	}

	// Products
	if ($(".main-wrap .products").length > 0) {
		const $mainProducts = $(".main-wrap .products");
		$mainProducts.find('.products-item a').on('click', function() {
			pushDataLayer({
				'event': 'click_product',
				'click_product' : {
					'productTitle' : convertTitle($(this).find('.title').text()),
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});
	// 	$(window).on("scroll", function () {
	// 		// let $item = $mainProducts.find(".products-item");
	// 		let tt = $mainProducts.offset().top;
	// 		let tb = $mainProducts.offset().top + $mainProducts.height();
	// 		// animation
	// 		if (OLEDUI.isInScreen(tt, tb)) {
	// 			$mainProducts.addClass("on");
	// 		}
	// 		// $item.each(function () {
	// 		// 	let $this = $(this);
	// 		// 	let t = $this.offset().top;
	// 		// 	let b = $this.offset().top + $this.height();
	// 		// 	if (OLEDUI.isInScreen(t, b)) {
	// 		// 		OLEDUI.watchTimer($this);
	// 		// 	}
	// 		// });
	// 	});
	}

	// OLED Inside
	if ($(".main-wrap .inside").length > 0) {
		const $mainInside = $(".main-wrap .inside");
		$mainInside.find('a').on('click', function() {
			pushDataLayer({
				'event': 'click_article',
				'click_article' : {
					'articleTitle' : convertTitle($(this).find('.title').text()),
					'clickSection' : '',
					'articleCategory' : convertTitle($mainInside.find('.main-title .title').text()),
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});
	// 	$(window).on("scroll", function () {
	// 		let $item = $mainInside.find(".inside-item");
	// 		let st = $mainInside.height() / 2;
	// 		let tt = $mainInside.offset().top;
	// 		let tb = $mainInside.offset().top + $mainInside.height();
		
	// 		if (OLEDUI.isInScreen(tt, tb)) {
	// 			$mainInside.addClass("on");
	// 		}
	// 		$item.each(function () {
	// 			let $this = $(this);
	// 			let t = $this.offset().top;
	// 			let b = $this.offset().top + $this.height();
	// 			if (OLEDUI.isInScreen(t, b)) {
	// 				OLEDUI.watchTimer($this);
	// 			}
	// 		});
	// 	});
	}

	// Instagram Feed
    OLEDUI.instaSwiper = function (t) {
        const swipeCont = t;
		const $pageWrap = $(swipeCont).find(".swiper-pagination");
		const $pageActive = $(swipeCont).find(".swiper-pagination-active");
        const evolutionSwiper = new Swiper(t, {
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			loop: false,
			speed: 500,
			autoplay: false,
            centeredSlides: false,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			preloadImages: false,
			allowTouchMove: true,
			lazy: false,
			// lazy: {
			// 	loadPrevNext: true,
			// },
			slidesPerView: "1",
            spaceBetween: 0,
			breakpoints: {
				// min 
				751: {
					slidesPerView: "4",
					slidesPerGroup : 4,
					allowTouchMove: false,
				},
			},
			on: {
				activeIndexChange: function () {
					const pageAni = function(){
						const $bulletActive = $(swipeCont).find(".swiper-pagination-bullet-active");
						const posX = $bulletActive.offset().left - $pageWrap.offset().left;							
						$pageActive.css("left", posX);
					}
					const pageAniAction = function(){
						setTimeout(() => {
							pageAni();
							$(window).on('resize',function(){
								pageAni();
							})
						}, 100);
					}					
					pageAniAction();
					$(window).on('resize', function(){
						pageAniAction();
					})

					pushDataLayer({
                        'event': 'swipe_gallery',
                        'swipe_gallery' : {
                            'galleryTitle': convertTitle($(swipeCont).closest('.insta-feed ').find('.main-title .title').text())
                        }
                    });
				},
			},
		});
    }
	if ($(".main-wrap .insta-feed").length > 0) {
		const $instaFeed = $(".main-wrap .insta-feed");

		OLEDUI.instaSwiper(".insta-swiper");

		const setBullet = function() {
			setTimeout(function(){
				const $pageBullet = $instaFeed.find(".swiper-pagination-bullet");
				const $pageActive = $instaFeed.find(".swiper-pagination-active");
				const leng = $pageBullet.length;
				const num = (100 / leng).toFixed(2);
				$pageActive.css("width", num + "%");
			}, 100);
		}
		setBullet();
		$(window).on('resize', function(){
			setBullet();
		});
		$instaFeed.find('.link-item').on('click', function() {
			let splitImgName = $(this).find('.image-area img').eq(0).attr('data-src').split('/');
			let imgName = splitImgName[splitImgName.length - 1].replaceAll('-l.jpg', '').replaceAll('-s.jpg', '').replaceAll('.jpg', '');
			pushDataLayer({
				'event': 'click_image',
				'click_image' : {
					'imageType' : 'instagram',
					'imageName' : imgName,
				}
			});
		});

		$instaFeed.find('.cta-wrap a').on('click', function() {
			pushDataLayer({
				'event': 'click_button',
				'click_button' : {
					'buttonText' : convertTitle($(this).text()),
					'sectionName' : convertTitle($instaFeed.find('.main-title .title').text()),
					'sectionContentTitle' : convertTitle($instaFeed.find('.main-title .desc').text()),
					'sectionContentSub' : '',
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});

		// let inScreen = true;
		// $(window).on("scroll", function () {
		// 	const $this= $(instaSwiperCont);
		// 	let st = $this.height() / 2;
		// 	let t = $this.offset().top;
        //     let b = $this.offset().top + $this.height();
		// 	// animation
		// 	if (OLEDUI.isInScreen(t, b)) {
		// 		if( inScreen == true ) {                    
        //             $instaFeed.addClass("on");
        //             inScreen = false;
        //         }				
		// 	}
		// });
	}
});
